<template>
  <div v-loading="loading">
    <div v-if="stepsExist">
      <div v-for="(step, i) in steps" :key="'step_' + step.keyIndex + '_' + i">
        <div class="center-container">
          <div class="arrow" v-if="showTopAdd"></div>
          <el-button
            icon="el-icon-plus"
            circle
            @click="addNewAction(listType, null)"
            class="step-btn"
            size="mini"
            v-if="showTopAdd"
          ></el-button>
          <div class="arrow" v-if="showTopAdd"></div>
          <div
            :style="`${
              step.type == 'CONDITION'
                ? 'display: grid; justify-content: center; grid-template-columns: 1fr 500px 1fr;'
                : 'display: flex; justify-content: center; align-items: center;'
            }`"
          >
            <div class="arrow-container" v-if="step.type == 'CONDITION'">
              <div class="left-arrow">
                <el-button
                  class="left-add"
                  icon="el-icon-plus"
                  circle
                  @click="addNewAction('then', step)"
                  style="color: #409eff; font-size: 12px"
                  size="mini"
                ></el-button>
                <span class="left-label">If</span>
              </div>
              <div v-if="step.then && step.then.length">
                <ConditionalBlock
                  :step="step"
                  :listType="'then'"
                  :entityAllFilters="entityAllFilters"
                  :currentEntity="currentEntity"
                  :allEntities="allEntities"
                  :allTemplates="allTemplates"
                  :allTemplateFieldMap="allTemplateFieldMap"
                  :existedDataConditionMap="existedDataConditionMap"
                  :form="form"
                  :allEntityFilters="allEntityFilters"
                  :relationshipsData="relationshipsData"
                  :mainAction="mainAction"
                  :allFormbuilders="allFormbuilders"
                  :entityRootFolders="entityRootFolders"
                  :company_users="company_users"
                  :allContacts="allContacts"
                ></ConditionalBlock>
              </div>
              <div v-else class="end-block">END</div>
            </div>
            <div class="step-box">
              <div class="d-flex">
                <div class="mt-1">
                  <el-radio
                    v-model="step.type"
                    label="CONDITION"
                    v-if="isEndNode(step)"
                    >Condition step</el-radio
                  >
                  <el-radio v-model="step.type" label="ACTION"
                    >Action step</el-radio
                  >
                  <el-tooltip
                    v-if="step.type == 'ACTION'"
                    class="item"
                    effect="dark"
                    content="Trigger only when user action not from other entity actions"
                    placement="top-start"
                  >
                    <el-checkbox v-model="step.only_main_trigger">
                    </el-checkbox>
                  </el-tooltip>
                </div>
                <div class="symbol" style="margin-left: auto">
                  <div class="rectangle" v-if="step.type == 'CONDITION'">
                    <p>Condition</p>
                  </div>
                  <div class="rect-action" v-else>
                    <p>Action</p>
                  </div>
                </div>
              </div>
              <div v-if="step.type == 'CONDITION'">
                <div class="d-flex">
                  <p class="step-title">
                    {{
                      step.filter_reverse
                        ? "If following conditions not satified then only workflow triggers"
                        : "What conditions should trigger workflow actions?"
                    }}
                  </p>
                  <el-link
                    type="danger"
                    class="ml-1"
                    icon="el-icon-delete"
                    :underline="false"
                    @click="checkAnddeleteNode(step.keyIndex)"
                  ></el-link>
                  <el-tooltip
                    v-if="step.filter_id"
                    class="item"
                    effect="dark"
                    content="Trigger workflow actions if this condition is not satisfied"
                    placement="top-start"
                  >
                    <el-checkbox class="m-1 mt-1" v-model="step.filter_reverse">
                    </el-checkbox>
                  </el-tooltip>
                </div>
                <el-select
                  v-model="step.filter_id"
                  placeholder="Condition"
                  clearable
                  filterable
                  style="width: 100%"
                >
                  <el-option
                    v-for="filter in entityAllFilters"
                    :key="filter._id"
                    :label="filter.filter_name"
                    :value="filter._id"
                    >{{ filter.filter_name }}</el-option
                  >
                </el-select>
              </div>
              <div v-else>
                <div class="d-flex">
                  <p class="step-title">Configure the action</p>
                  <el-link
                    type="danger"
                    class="ml-1"
                    icon="el-icon-delete"
                    :underline="false"
                    @click="checkAnddeleteNode(step.keyIndex)"
                  ></el-link>
                </div>
                <div class="action-row">
                  <el-select
                    v-model="step.action"
                    placeholder="Action type"
                    @change="notifyUser"
                  >
                    <el-option-group label="Self entity">
                      <el-option
                        v-for="(op, i) in selfEntityOption"
                        :key="op.key + i"
                        :value="'self/' + op.key"
                        :label="op.label"
                      ></el-option>
                      <el-option
                        value="self/DELETE"
                        label="Delete"
                        v-if="checkUpdateActionOrNot"
                      >
                      </el-option>
                    </el-option-group>
                    <el-option-group label="Other entity">
                      <el-option
                        v-for="(op, i) in otherEntityOption"
                        :key="op.key + i"
                        :value="'other/' + op.key"
                        :label="op.label"
                      ></el-option>
                    </el-option-group>
                    <el-option-group label="Create folders">
                      <el-option
                        value="file/ADD_FOLDER"
                        label="Add folder"
                      ></el-option>
                      <el-option
                        value="file/DELETE_FOLDER"
                        label="Delete folder"
                      ></el-option>
                    </el-option-group>
                    <el-option-group label="Form builder">
                      <el-option
                        value="formbuilder/ADD_FORM"
                        label="Send form"
                      ></el-option>
                    </el-option-group>
                    <!-- <el-option-group label="Notifications">
                      <el-option
                        v-for="(op, i) in ['EMAIL']"
                        :key="op + i"
                        :value="'notify/' + op"
                        :label="op.toLowerCase().replace('_', ' ')"
                      >
                      </el-option>
                    </el-option-group>-->
                  </el-select>
                  <!-- Refresh entity field-->
                  <el-button
                    v-if="step.action == 'other/REFRESH_ENTITY'"
                    @click="openRefreshEntityCofigureModal(step)"
                    type="text"
                    icon="el-icon-s-tools"
                    >Configure</el-button
                  >
                  <!-- Refresh entity field-->
                  <!-- Send form builder-->
                  <el-select
                    v-if="step.action == 'formbuilder/ADD_FORM'"
                    v-model="step.entity_from"
                    placeholder="Send to"
                    @change="
                      (changedValue) => getEntityDetails(changedValue, step)
                    "
                    clearable
                    filterable
                  >
                    <el-option-group label="Entity fields">
                      <el-option
                        v-for="(field, i) in getUserEntities(
                          getFieldsByType(['ENTITY'], allFieldsWithDataTable)
                        )"
                        :key="field + i"
                        :value="field.key"
                        :label="field.label"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                  <el-select
                    v-if="step.action == 'formbuilder/ADD_FORM'"
                    v-model="step.formbuilder_id"
                    placeholder="Select form"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(field, i) in getFormbuildersRelated(
                        step.entity_from,
                        allFields
                      )"
                      :key="field + i"
                      :value="field._id"
                      :label="field.name"
                    ></el-option>
                  </el-select>
                  <el-button
                    v-if="step.action == 'formbuilder/ADD_FORM'"
                    @click="openFormbuilderPrimaryFieldsModal(step)"
                    type="plain"
                    size="mini"
                    style="color: #24a0ed"
                    >Map values</el-button
                  >
                  <!-- Send form builder-->
                  <!--Add default folders-->
                  <div v-if="step.action == 'file/ADD_FOLDER'">
                    <el-button
                      @click="openFolderCofigureModal(step)"
                      icon="el-icon-s-tools"
                      >Configure</el-button
                    >
                  </div>
                  <!--Add default folders-->
                  <!--Delete folders-->
                  <div v-if="step.action == 'file/DELETE_FOLDER'">
                    <el-button
                      @click="openDeleteFolderCofigureModal(step)"
                      icon="el-icon-s-tools"
                      >Configure</el-button
                    >
                  </div>
                  <!--Delete folders-->
                  <!-- Self entity update -->
                  <el-select
                    v-if="step.action == 'self/UPDATE'"
                    v-model="step.update_field"
                    placeholder="Update field"
                    @change="(changedValue) => setFieldDetails(step)"
                    clearable
                    filterable
                  >
                    <el-option
                      v-for="(field, i) in getFieldsByType([], allFields)"
                      :key="field + i"
                      :value="field.key"
                      :label="field.label"
                    ></el-option>
                  </el-select>
                  <div v-if="step.action == 'self/SEND EMAIL'">
                    <el-button
                      class="email-config-button"
                      @click="openEmailConfiguration(step)"
                    >
                      <i style="color: #409eff" class="el-icon-setting">
                        configure email
                      </i>
                    </el-button>
                  </div>
                  <div
                    v-if="
                      !step.update_field_mapped_with &&
                      step.update_field &&
                      step.action == 'self/UPDATE' &&
                      !stepRefresh &&
                      allFields &&
                      allFields.length &&
                      getFieldProperty(
                        allFields,
                        step.update_field,
                        'key',
                        '',
                        {}
                      )
                    "
                  >
                    <AnyFieldsExecute
                      v-if="
                        !step.make_empty &&
                        getFieldProperty(
                          allFields,
                          step.update_field,
                          'key',
                          'input_type',
                          ''
                        ) !== 'AUTO_INCREMENT_NUMBER'
                      "
                      :fieldsData="allFields"
                      :field="
                        getFieldProperty(
                          allFields,
                          step.update_field,
                          'key',
                          '',
                          {}
                        )
                      "
                      :form="step"
                    ></AnyFieldsExecute>
                    <el-select
                      v-else-if="
                        !step.make_empty &&
                        getFieldProperty(
                          allFields,
                          step.update_field,
                          'key',
                          'input_type',
                          ''
                        ) == 'AUTO_INCREMENT_NUMBER'
                      "
                      v-model="step.operator"
                      placeholder="Operator"
                      clearable
                      filterable
                    >
                      <el-option
                        value="GENERATE_NEW_NUMBER"
                        label="Generate new number"
                      ></el-option>
                    </el-select>
                    <el-checkbox
                      v-model="step.make_empty"
                      :disabled="step[step.update_field] ? true : false"
                      >Make empty</el-checkbox
                    >
                  </div>
                  <div
                    v-if="
                      !step.make_empty &&
                      step.update_field &&
                      step.action == 'self/UPDATE' &&
                      !stepRefresh &&
                      allFields &&
                      allFields.length &&
                      getFieldProperty(
                        allFields,
                        step.update_field,
                        'key',
                        '',
                        {}
                      ) &&
                      getFieldProperty(
                        allFields,
                        step.update_field,
                        'key',
                        'input_type',
                        ''
                      ) !== 'AUTO_INCREMENT_NUMBER'
                    "
                    class="d-flex"
                  >
                    <span
                      style="margin-top: 6px"
                      v-if="!step.update_field_mapped_with"
                      >OR</span
                    >
                    <el-select
                      v-if="step.action == 'self/UPDATE'"
                      v-model="step.update_field_mapped_with"
                      placeholder="Update with"
                      clearable
                      filterable
                      :disabled="step[step.update_field] ? true : false"
                    >
                      <el-option
                        v-for="(field, i) in getFieldsByType(
                          [
                            getFieldProperty(
                              allFields,
                              step.update_field,
                              'key',
                              'input_type',
                              ''
                            ),
                          ],
                          allFields
                        ).filter((e) => e.key != step.update_field)"
                        :key="field + i"
                        :value="field.key"
                        :label="field.label"
                      ></el-option>
                      <el-option
                        v-if="
                          step.action == 'self/UPDATE' &&
                          step.update_field &&
                          ['DATE', 'DATE_TIME'].includes(
                            getFieldProperty(
                              allFields,
                              step.update_field,
                              'key',
                              'input_type',
                              ''
                            )
                          )
                        "
                        value="CURRENT_TIME"
                        label="Map with current time"
                      >
                      </el-option>
                    </el-select>
                  </div>
                  <!-- Automation Block -->
                  <!-- {{ step.action }} -->
                  <!-- @click="openPrimaryFieldsModal(step)" -->
                  <el-button
                    @click="openAutomateDrawer(step)"
                    v-if="step.action == 'self/AUTOMATE'"
                    icon="el-icon-s-tools"
                    >Configure</el-button
                  >

                  <!-- Self entity update -->

                  <!-- other entity update -->
                  <el-select
                    v-if="step.action == 'other/UPDATE'"
                    v-model="step.entity_from"
                    placeholder="Take entity from"
                    @change="
                      (changedValue) => getEntityDetails(changedValue, step)
                    "
                    clearable
                    filterable
                  >
                    <el-option-group label="Relational"></el-option-group>
                    <el-option-group label="Entity fields">
                      <el-option
                        v-for="(field, i) in getFieldsByType(
                          ['ENTITY'],
                          allFieldsWithDataTable
                        )"
                        :key="field + i"
                        :value="field.key"
                        :label="field.label"
                      ></el-option>
                    </el-option-group>
                  </el-select>
                  <el-select
                    v-if="step.entity_id && step.action == 'other/UPDATE'"
                    v-model="step.update_field"
                    placeholder="Update field"
                    clearable
                    filterable
                    @change="
                      (changedValue) =>
                        setUpdateField(
                          changedValue,
                          step,
                          actionEntityFields[step.entity_id]
                        )
                    "
                  >
                    <el-option
                      v-for="(field, i) in actionEntityFields[step.entity_id]"
                      :key="field + i"
                      :value="field.key"
                      :label="field.label"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="
                      step.input_type == 'DATA_TABLE' &&
                      step.action == 'other/UPDATE' &&
                      step.update_field
                    "
                    v-model="step.data_table_column_update_field_match"
                    placeholder="Match with"
                    multiple
                  >
                    <el-option
                      v-for="(field, i) in getFieldProperty(
                        actionEntityFields[step.entity_id],
                        step.update_field,
                        'key',
                        'data_table_columns'
                      )"
                      :key="field + i"
                      :value="field.key"
                      :label="field.label"
                    ></el-option>
                  </el-select>
                  <el-tooltip
                    v-if="
                      step.entity_from &&
                      step.action == 'other/UPDATE' &&
                      step.input_type == 'DATA_TABLE' &&
                      step.update_field
                    "
                    class="item"
                    effect="dark"
                    content="Allow Create new data if existed data not found"
                    placement="top-start"
                  >
                    <el-checkbox
                      class="mt-1"
                      v-model="step.create_new_data"
                      v-if="
                        step.entity_from &&
                        step.action == 'other/UPDATE' &&
                        step.input_type == 'DATA_TABLE' &&
                        step.update_field
                      "
                    ></el-checkbox>
                  </el-tooltip>
                  <el-select
                    v-if="
                      step.input_type == 'DATA_TABLE' &&
                      step.action == 'other/UPDATE' &&
                      step.update_field &&
                      step.data_table_column_update_field_match &&
                      step.data_table_column_update_field_match.length
                    "
                    v-model="step.data_table_column_update_field"
                    placeholder="Update table field"
                  >
                    <el-option
                      v-for="(field, i) in (
                        getFieldProperty(
                          actionEntityFields[step.entity_id],
                          step.update_field,
                          'key',
                          'data_table_columns'
                        ) || []
                      ).filter(
                        (e) =>
                          (
                            step.data_table_column_update_field_match || []
                          ).indexOf(e.key) == -1
                      )"
                      :key="field + i"
                      :value="field.key"
                      :label="field.label"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-if="step.update_field && step.action == 'other/UPDATE'"
                    v-model="step.operator"
                    placeholder="Operator"
                    clearable
                    filterable
                  >
                    <template
                      v-if="
                        operators[getFieldInputType(step)] &&
                        operators[getFieldInputType(step)].length
                      "
                    >
                      <el-option
                        v-for="(field, i) in operators[getFieldInputType(step)]"
                        :key="field + i"
                        :value="field.key"
                        :label="field.label"
                      ></el-option>
                    </template>
                    <template v-else>
                      <el-option value="=" label="Value (=)"></el-option>
                    </template>
                  </el-select>
                  <div
                    v-if="
                      step.action == 'other/UPDATE' &&
                      step.operator &&
                      step.keyIndex != stepRefreshIndex &&
                      actionEntityFields[step.entity_id] &&
                      step.operator !== 'GENERATE_NEW_NUMBER'
                    "
                  >
                    <div v-if="step.input_type == 'DATA_TABLE'">
                      <span
                        v-if="
                          getMappedField(
                            getFieldProperty(
                              actionEntityFields[step.entity_id],
                              step.update_field,
                              'key',
                              'data_table_columns'
                            ) || [],
                            step.data_table_column_update_field,
                            'key'
                          )
                        "
                      >
                        <AnyFieldsExecute
                          :fieldsData="
                            getFieldProperty(
                              actionEntityFields[step.entity_id],
                              step.update_field,
                              'key',
                              'data_table_columns'
                            ) || []
                          "
                          :field="
                            getMappedField(
                              getFieldProperty(
                                actionEntityFields[step.entity_id],
                                step.update_field,
                                'key',
                                'data_table_columns'
                              ) || [],
                              step.data_table_column_update_field,
                              'key'
                            )
                          "
                          :form="step"
                        ></AnyFieldsExecute>
                      </span>
                    </div>
                    <div
                      v-else-if="
                        getMappedField(
                          actionEntityFields[step.entity_id],
                          step.update_field,
                          'key'
                        )
                      "
                    >
                      <AnyFieldsExecute
                        v-if="
                          getMappedField(
                            actionEntityFields[step.entity_id],
                            step.update_field,
                            'key'
                          )
                        "
                        :fieldsData="actionEntityFields[step.entity_id]"
                        :field="
                          getMappedField(
                            actionEntityFields[step.entity_id],
                            step.update_field,
                            'key'
                          )
                        "
                        :form="step"
                      ></AnyFieldsExecute>
                    </div>
                    <el-input
                      v-else
                      v-model="step.value"
                      clearable
                      :disabled="step.value_field ? true : false"
                      :type="
                        getFieldInputType(step) == 'NUMBER' ? 'number' : 'text'
                      "
                    ></el-input>
                  </div>
                  <div
                    style="text-align: center !important; padding-top: 3px"
                    v-if="
                      step.operator &&
                      step.action == 'other/UPDATE' &&
                      step.operator !== 'GENERATE_NEW_NUMBER'
                    "
                  >
                    <span>OR</span>
                  </div>
                  <el-select
                    v-if="
                      step.operator &&
                      step.action == 'other/UPDATE' &&
                      step.operator !== 'GENERATE_NEW_NUMBER'
                    "
                    v-model="step.value_field"
                    placeholder="Update with"
                    clearable
                    filterable
                    :disabled="
                      (step.value && typeof step.value !== 'object') ||
                      (step.value &&
                        typeof step.value == 'object' &&
                        step.value.length)
                        ? true
                        : false
                    "
                    @change="
                      (changedValue) =>
                        setValueField(changedValue, step, allFields)
                    "
                  >
                    <el-option
                      v-for="(field, i) in getFieldsByType(
                        [getFieldInputType(step)],
                        allFields
                      )"
                      :key="field + i"
                      :value="
                        field.data_table_key
                          ? field.data_table_key + '/' + field.key
                          : field.key
                      "
                      :label="field.label"
                    ></el-option>
                  </el-select>
                  <!-- other entity update -->
                  <!-- other entity add -->
                  <el-select
                    v-if="step.action == 'other/ADD'"
                    v-model="step.entity_from"
                    placeholder="Select entity"
                    @change="
                      (changedValue) => setEntityFromDetails(changedValue, step)
                    "
                    clearable
                    filterable
                  >
                    <el-option-group label="Relational">
                      <!-- <el-option
                        v-for="(relationship, index) of relationships"
                        :value="'relationship/' + relationship.relationship_id"
                        :key="index"
                        :label="relationship.relationship_title"
                      >
                      </el-option>-->
                    </el-option-group>
                    <el-option-group label="Other entities">
                      <el-option
                        v-for="(entity, index) of filteredEntities"
                        :value="'other/' + entity._id"
                        :key="index"
                        :label="entity.name"
                      >
                        <span style="float: left">{{ entity.name }}</span>
                      </el-option>
                    </el-option-group>
                  </el-select>

                  <el-select
                    v-if="step.action == 'other/AUTOMATE'"
                    v-model="step.entity_from"
                    placeholder="Select entity"
                    @change="
                      (changedValue) => setEntityFromDetails(changedValue, step)
                    "
                    clearable
                    filterable
                  >
                    <el-option-group label="Relational">
                      <!-- <el-option
                        v-for="(relationship, index) of relationships"
                        :value="'relationship/' + relationship.relationship_id"
                        :key="index"
                        :label="relationship.relationship_title"
                      >
                      </el-option>-->
                    </el-option-group>
                    <el-option-group label="Other entities">
                      <el-option
                        v-for="(entity, index) of filteredEntities"
                        :value="'other/' + entity._id"
                        :key="index"
                        :label="entity.name"
                      >
                        <span style="float: left">{{ entity.name }}</span>
                      </el-option>
                    </el-option-group>
                  </el-select>
                  <el-button
                    @click="openPrimaryFieldsModal(step)"
                    v-if="step.entity_from && step.action == 'other/AUTOMATE'"
                    icon="el-icon-s-tools"
                    size="mini"
                    >Mapping</el-button
                  >

                  <el-button
                    @click="openPrimaryFieldsModal(step)"
                    v-if="step.entity_from && step.action == 'other/ADD'"
                    icon="el-icon-s-tools"
                    >Configure</el-button
                  >
                  <div>
                    <el-tooltip
                      v-if="
                        step.entity_from &&
                        step.action == 'other/ADD' &&
                        IsDataTableSelected(step)
                      "
                      class="item"
                      effect="dark"
                      content="Data table fields are selected so from where new data has to be created?"
                      placement="top-start"
                    >
                      <el-radio-group v-model="step.take_data_from">
                        <el-radio label="original_data">Original data</el-radio>
                        <el-radio label="selected_data">Selected data</el-radio>
                        <el-radio label="total_data">Total data</el-radio>
                      </el-radio-group>
                    </el-tooltip>
                  </div>
                  <div class="d-flex">
                    <el-tooltip
                      v-if="
                        step.entity_from &&
                        step.action == 'other/ADD' &&
                        IsDataTableSelected(step) &&
                        step.take_data_from == 'selected_data'
                      "
                      class="item"
                      effect="dark"
                      content="Not include splitted data"
                      placement="top-start"
                    >
                      <el-checkbox
                        class="mt-1"
                        v-if="
                          step.entity_from &&
                          step.action == 'other/ADD' &&
                          IsDataTableSelected(step) &&
                          step.take_data_from == 'selected_data'
                        "
                        v-model="step.allow_original"
                      >
                      </el-checkbox>
                    </el-tooltip>
                    <el-tooltip
                      v-if="
                        step.entity_from &&
                        step.action == 'other/ADD' &&
                        mainAction == 'ADDED'
                      "
                      class="item"
                      effect="dark"
                      content="Delete added child data if parent is deleted"
                      placement="top-start"
                    >
                      <el-checkbox
                        class="mt-1"
                        v-if="step.entity_from && step.action == 'other/ADD'"
                        v-model="step.delete_child_data"
                      >
                      </el-checkbox>
                    </el-tooltip>
                  </div>
                  <div class="d-flex">
                    <el-tooltip
                      v-if="step.entity_from && step.action == 'other/ADD'"
                      class="item"
                      effect="dark"
                      content="Update existed data with condition"
                      placement="top-start"
                    >
                      <el-select
                        v-model="step.update_filter_id"
                        placeholder="Update with condition"
                        clearable
                        filterable
                      >
                        <el-option
                          v-for="filter in allEntityFilters[step.entity_id] ||
                          []"
                          :key="filter._id"
                          :label="filter.filter_name"
                          :value="filter._id"
                          >{{ filter.filter_name }}</el-option
                        >
                      </el-select>
                    </el-tooltip>
                  </div>
                  <div>
                    <el-tooltip
                      v-if="
                        step.entity_from &&
                        step.action == 'other/ADD' &&
                        step.update_filter_id
                      "
                      class="item"
                      effect="dark"
                      content="Allow Create new data if existed data not found"
                      placement="top-start"
                    >
                      <el-checkbox
                        class="mt-1"
                        v-model="step.create_new_data"
                        v-if="
                          step.entity_from &&
                          step.action == 'other/ADD' &&
                          step.update_filter_id
                        "
                      ></el-checkbox>
                    </el-tooltip>
                  </div>
                  <div
                    v-if="
                      step.entity_from &&
                      step.action == 'other/ADD' &&
                      IsDataTableSelected(step)
                    "
                  >
                    <el-badge
                      class="badge"
                      type="warning"
                      :value="
                        step &&
                        step.data_table_filters &&
                        step.data_table_filters.length
                          ? step.data_table_filters.length
                          : 0
                      "
                    >
                      <el-button
                        icon="el-icon-plus"
                        class="mt-1"
                        size="mini"
                        :underline="false"
                        @click="addDataFilter(step)"
                        >Add data table filter</el-button
                      >
                    </el-badge>
                  </div>
                  <!-- other entity add -->
                </div>
              </div>
            </div>
            <div class="arrow-container" v-if="step.type == 'CONDITION'">
              <div class="right-arrow">
                <el-button
                  class="right-add"
                  icon="el-icon-plus"
                  circle
                  @click="addNewAction('else', step)"
                  style="color: #409eff; font-size: 12px"
                  size="mini"
                ></el-button>
                <span class="right-label">else</span>
              </div>
              <div v-if="step.else && step.else.length">
                <ConditionalBlock
                  :step="step"
                  :listType="'else'"
                  :entityAllFilters="entityAllFilters"
                  :currentEntity="currentEntity"
                  :allEntities="allEntities"
                  :allTemplates="allTemplates"
                  :allTemplateFieldMap="allTemplateFieldMap"
                  :existedDataConditionMap="existedDataConditionMap"
                  :form="form"
                  :allEntityFilters="allEntityFilters"
                  :relationshipsData="relationshipsData"
                  :mainAction="mainAction"
                  :allFormbuilders="allFormbuilders"
                  :entityRootFolders="entityRootFolders"
                  :company_users="company_users"
                  :allContacts="allContacts"
                ></ConditionalBlock>
              </div>
              <div v-else class="end-block">END</div>
            </div>
          </div>
        </div>
        <div
          class="center-container"
          v-if="step.type != 'CONDITION'"
          style="display: flex; justify-content: center"
        >
          <div>
            <div class="arrow"></div>
            <el-button
              icon="el-icon-plus"
              circle
              @click="addNewAction(listType)"
              style="color: #409eff; font-size: 12px"
              size="mini"
              class="step-btn"
            ></el-button>
            <div class="arrow"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Mapping primary fields -->
    <dialog-component
      :visible="mapPrimaryFieldsModal"
      :title="'Map Entity Fields'"
      @before-close="resetPrimarymapTemplate"
      :containerWidth="'75%'"
      :containerHeight="'80%'"
    >
      <div>
        <div style="text-align: left">
          <el-collapse accordion v-model="activeStep">
            <el-collapse-item
              v-for="(templateId, i) in actionEntityTemplates"
              :key="i + '_' + templateId"
              :name="i + 1"
              class="rule-collapse"
            >
              <template slot="title" style="border: 1px solid #e4e7ed">
                <div style="width: 100%" class="ml-1">
                  <label v-if="allTemplateFieldMap[templateId]">
                    <b>
                      {{ i + 1 }}.
                      {{ allTemplateFieldMap[templateId].name }}
                    </b>
                  </label>
                </div>
              </template>
              <!-- || e.type!= "CONTENT" -->
              <el-table
                v-if="allTemplateFieldMap[templateId]"
                :data="
                  allTemplateFieldMap[templateId].fields.filter(
                    (e) => e.input_type != 'FORMULA'
                  )
                "
                border
                class="scrollbar-content"
              >
                <el-table-column label="Field" prop="label" width="200">
                </el-table-column>
                <el-table-column label="Mapped with" prop="key" width="250">
                  <template slot-scope="scope">
                    <div class="d-flex">
                      <el-select
                        v-model="
                          actionEntityPrimaryFieldsMap[
                            'mapped/' + scope.row.key
                          ]
                        "
                        placeholder="Update with"
                        clearable
                        filterable
                        :disabled="
                          actionEntityPrimaryFieldsMap[scope.row.key] &&
                          actionEntityPrimaryFieldsMap[scope.row.key].length
                            ? true
                            : false
                        "
                      >
                        <el-option
                          v-for="(field, i) in fetchTemplateFieldsByType(
                            [scope.row.inputType],
                            allFieldsWithDataTable,
                            scope.row.entity_id
                          )"
                          :key="field + i"
                          :value="field.key"
                          :label="field.label"
                          :disabled="tableFieldDisabled(field)"
                        ></el-option>
                        <el-option
                          v-if="
                            scope.row.input_type == 'ENTITY' &&
                            scope.row.entity_id == currentEntity._id
                          "
                          :label="
                            'Current ' +
                            (currentEntity && currentEntity.name
                              ? currentEntity.name
                              : 'entity')
                          "
                          value="currentEntityData"
                        ></el-option>
                      </el-select>
                      <!-- <p style="white-space: nowrap" class="mr-1 ml-1 mt-1">
                          OR
                        </p>
                        <el-select
                          v-model="
                            actionEntityPrimaryFieldsMap['or/' + scope.row.key]
                          "
                          placeholder="Update with"
                          clearable
                          filterable
                          :disabled="
                            actionEntityPrimaryFieldsMap[scope.row.key] &&
                            actionEntityPrimaryFieldsMap[scope.row.key].length
                              ? true
                              : false
                          "
                        >
                          <el-option
                            v-for="(field, i) in fetchTemplateFieldsByType(
                              [scope.row.inputType],
                              allFieldsWithDataTable,
                              scope.row.entity_id
                            ).filter(
                              (el) =>
                                el.key !==
                                actionEntityPrimaryFieldsMap[
                                  'mapped/' + scope.row.key
                                ]
                            )"
                            :key="field + i"
                            :value="field.key"
                            :label="field.label"
                          ></el-option>
                          <el-option
                            v-if="
                              scope.row.input_type == 'ENTITY' &&
                              scope.row.entity_id == currentEntity._id
                            "
                            :label="
                              'Current ' +
                              (currentEntity && currentEntity.name
                                ? currentEntity.name
                                : 'entity')
                            "
                            value="currentEntityData"
                          ></el-option>
                        </el-select> -->
                      <el-tooltip
                        v-if="
                          !actionEntityPrimaryFieldsMap[scope.row.key] &&
                          currentStep &&
                          currentStep.update_filter_id
                        "
                        class="item"
                        effect="dark"
                        content="Include in filter"
                        placement="top-start"
                        style="margin-top: 8px"
                      >
                        <el-checkbox
                          v-if="
                            !actionEntityPrimaryFieldsMap[scope.row.key] &&
                            currentStep &&
                            currentStep.update_filter_id
                          "
                          v-model="
                            actionEntityPrimaryFieldsMap[
                              'filter/' + scope.row.key
                            ]
                          "
                        ></el-checkbox>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="Default value" prop="key" width="250">
                  <template slot-scope="scope">
                    <AnyFieldsExecute
                      v-if="
                        actionEntityPrimaryFieldsMap['mapped/' + scope.row.key]
                          ? false
                          : true
                      "
                      :fieldsData="allTemplateFieldMap[templateId].fields"
                      :field="
                        getFieldProperty(
                          allTemplateFieldMap[templateId].fields,
                          scope.row.key,
                          'key',
                          '',
                          {}
                        )
                      "
                      :form="actionEntityPrimaryFieldsMap"
                    ></AnyFieldsExecute>
                  </template>
                </el-table-column>
                <el-table-column
                  label="Fill with relational data"
                  width="250"
                  v-if="fillWithRelationVisible"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-if="scope.row.input_type == 'ENTITY'"
                      v-model="
                        actionEntityPrimaryFieldsMap[
                          'relation/' + scope.row.key
                        ]
                      "
                      filterable
                      clearable
                      @change="rowValueChange(scope.row)"
                    >
                      <el-option
                        v-for="(rel, index) in getRelations(scope.row)"
                        :key="index"
                        :value="rel.key + '#' + rel.entity_id"
                        :label="rel.label"
                      >
                      </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column label="If existed found" prop="label">
                  <template slot-scope="scope">
                    <el-select
                      v-if="
                        scope.row.inputType == 'NUMBER' ||
                        scope.row.inputType == 'CURRENCY'
                      "
                      v-model="actionEntityFieldsOparatorMap[scope.row.key]"
                      placeholder="Operator"
                      clearable
                      filterable
                    >
                      <el-option
                        v-for="(field, i) in operators[scope.row.inputType]"
                        :key="field + i"
                        :value="field.key"
                        :label="field.label"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  label="If duplicate table rows found"
                  prop="label"
                  v-if="currentStep && currentStep.allow_original"
                >
                  <template slot-scope="scope">
                    <el-select
                      v-if="
                        (scope.row.inputType == 'NUMBER' ||
                          scope.row.inputType == 'CURRENCY') &&
                        currentStep &&
                        currentStep.allow_original &&
                        scope.row.key.includes(':')
                      "
                      v-model="actionEntityFieldsOparationsMap[scope.row.key]"
                      placeholder="Operation"
                      clearable
                      filterable
                    >
                      <el-option value="SUM" label="Sum"></el-option>
                      <el-option value="AVERAGE" label="Average"></el-option>
                    </el-select>
                  </template>
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPrimarymapTemplate">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkSaveDisable"
          @click="savePrimarymapTemplate"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <!-- Add new data table filters -->
    <el-dialog
      :visible.sync="addDataTableStepFilters"
      title="Add filters"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :before-close="resetDataTableFilters"
      :width="getIsMobile ? '100%' : '50%'"
    >
      <div>
        <el-row>
          <el-link
            type="primary"
            icon="el-icon-plus"
            class="float-right"
            :underline="false"
            @click="addFilter(step)"
            >Add filter</el-link
          >
        </el-row>
        <el-row
          v-for="(filter, i) in dataTablefilters"
          :key="i + '_filters_field'"
          :gutter="10"
          class="mt-1"
        >
          <el-col :span="6">
            <el-select
              placeholder="Field"
              v-model="filter.selectField"
              @change="
                (changedValue) =>
                  checkCurrentFieldData(
                    changedValue,
                    filter,
                    getDataTableFields(allFieldsWithDataTable)
                  )
              "
            >
              <el-option
                v-for="(op, i) in getDataTableFields(allFieldsWithDataTable)"
                :key="i + '_filter_field'"
                :value="op.key"
                :label="op.label"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="filter.operator"
              :disabled="!filter.selectField"
              placeholder="Operator"
            >
              <el-option
                v-for="(operator, index) of getCurrentFieldOperators(filter)"
                :key="index + '_operator'"
                :label="operator.title"
                :value="operator.value"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="5"
            v-if="
              filter.operator != 'exists' &&
              filter.operator != 'notexists' &&
              filter.data_type != 'BOOLEAN'
            "
          >
            <el-select
              v-model="filter.data_source"
              placeholder="Data source"
              :disabled="!filter.operator"
            >
              <el-option
                v-for="(source, index) of allowedDataSources(filter)"
                :key="index"
                :label="source"
                :value="source"
              ></el-option>
            </el-select>
          </el-col>
          <el-col
            :span="6"
            v-if="
              filter.operator != 'exists' &&
              filter.operator != 'notexists' &&
              filter.data_type != 'BOOLEAN'
            "
          >
            <template v-if="filter.data_source == 'TEMPLATE'">
              <el-select
                placeholder="Resource field"
                v-model="filter.value_field"
                clearable
              >
                <el-option
                  v-for="(option, index) of getMatchedTypeEntityFields(filter)"
                  :value="option.key"
                  :label="option.label"
                  :key="index"
                ></el-option>
              </el-select>
            </template>
            <template v-else>
              <el-input
                v-model="filter.value"
                v-if="filter.data_type == 'STRING'"
                :disabled="!filter.data_source"
              ></el-input>
              <el-input-number
                v-else-if="filter.data_type == 'NUMBER'"
                v-model="filter.value"
                :controls="false"
                placeholder="value"
                :disabled="!filter.data_source"
              ></el-input-number>
            </template>
          </el-col>
          <el-col :span="2">
            <el-link
              class="mt-1 ml-2"
              type="danger"
              :underline="false"
              @click="removeFilter(i)"
            >
              <i class="el-icon-delete"></i>
            </el-link>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetDataTableFilters">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkFilterDisable"
          @click="saveDataTableFilters"
          >Save</el-button
        >
      </span>
    </el-dialog>
    <dialog-component
      :visible="mapFolderCofigureModal"
      :title="'Folder mapping'"
      @before-close="resetFolderCofigureTemplate"
      :containerWidth="'60%'"
    >
      <div
        v-if="deleteFolder"
        class="popUp-content"
        :style="'min-height: 100px;'"
      >
        <div class="delete-files-container">
          <span>Delete folder condtion</span>
        </div>
        <div>
          <div class="d-flex mt-1" style="gap: 5px">
            <el-select
              placeholder="From"
              v-model="deleteActionEntityFoldersList.type"
            >
              <el-option
                value="CURRENT_DATA"
                label="Current data"
                v-if="checkSelfTriggerAction"
              ></el-option>
              <el-option
                value="ROOT"
                :label="currentEntity.name + ' root'"
              ></el-option>
            </el-select>
            <el-select
              placeholder="Select root folder"
              v-if="actionEntityFoldersListType == 'ROOT'"
              v-model="deleteActionEntityFoldersList.folder"
              clearable
              class="ml-1"
            >
              <el-option
                v-for="folder in entityRootFolders"
                :key="folder._id"
                :value="folder._id"
                :label="folder.name"
              ></el-option>
            </el-select>
            <el-select
              placeholder="Query on"
              v-model="deleteActionEntityFoldersList.query_on"
              :disabled="!deleteActionEntityFoldersList.type ? true : false"
              class="ml-1"
            >
              <!-- <el-option value="UPDATED" label="Last updated"></el-option> -->
              <el-option value="CREATED" label="Created"></el-option>
            </el-select>
            <el-select
              placeholder="Query type"
              v-model="deleteActionEntityFoldersList.query_type"
              :disabled="!deleteActionEntityFoldersList.query_on ? true : false"
              class="ml-1"
            >
              <el-option value="YEAR" label="Year"></el-option>
              <el-option value="MONTH" label="Month"></el-option>
              <el-option value="DAYS" label="Days"></el-option>
            </el-select>
            <el-input-number
              placeholder="Query duration"
              v-model="deleteActionEntityFoldersList.query_duration"
              :min="1"
              :disabled="
                !deleteActionEntityFoldersList.query_type ? true : false
              "
              class="ml-1"
            ></el-input-number>
          </div>
        </div>
      </div>
      <div class="popUp-content" v-else>
        <el-select
          placeholder="Create on"
          v-model="actionEntityFoldersListType"
        >
          <el-option
            value="CURRENT_DATA"
            label="Current data"
            v-if="checkSelfTriggerAction"
          ></el-option>
          <el-option
            value="ROOT"
            :label="currentEntity.name + ' root'"
          ></el-option>
        </el-select>
        <el-select
          placeholder="Select root folder"
          v-if="actionEntityFoldersListType == 'ROOT'"
          v-model="actionEntityFoldersListFolder"
          clearable
          class="ml-1"
        >
          <el-option
            v-for="folder in entityRootFolders"
            :key="folder._id"
            :value="folder._id"
            :label="folder.name"
          ></el-option>
        </el-select>
        <div v-if="actionEntityFoldersList && actionEntityFoldersList.length">
          <el-scrollbar wrap-style="max-height: 600px;">
            <div class="files-container">
              <div
                v-for="(file, i) in actionEntityFoldersList"
                :key="file._id"
                class="single-file"
              >
                <div class="file-header" :style="'color: #409EFF;'">
                  <i class="el-icon-folder" v-if="file.type == 'FOLDER'"></i>
                  <i
                    class="el-icon-picture-outline"
                    v-else-if="file.file_format && isImage(file.file_format)"
                  ></i>
                  <i class="el-icon-tickets" v-else></i>
                </div>
                <div class="file-title">
                  <span v-if="file.name">{{
                    file.name | truncate(14, "...")
                  }}</span>
                  <span v-else-if="file.name_mapped_with">{{
                    file.name_mapped_with
                  }}</span>
                  <el-dropdown class="action-button">
                    <i
                      class="el-icon-more"
                      style="transform: rotate(90deg)"
                    ></i>
                    <el-dropdown-menu slot="dropdown">
                      <a @click="onDelete(i)">
                        <el-dropdown-item>Delete</el-dropdown-item>
                      </a>
                      <a @click="openSettingsModal(file, i)">
                        <el-dropdown-item
                          ><i class="el-icon-setting"></i>
                          Settings</el-dropdown-item
                        >
                      </a>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div class="single-file" @click="openNew">
                <div class="file-header" :style="'color: #409EFF;'">
                  <i class="el-icon-plus"></i>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
        <div class="no-files" v-else>
          <el-empty description="No files">
            <el-link type="primary" :underline="false" @click="openNew"
              >Create new</el-link
            >
          </el-empty>
        </div>
      </div>
    </dialog-component>
    <dialog-component
      :visible="entityRefreshModal"
      :title="'Refresh entit tables'"
      @before-close="resetRefreshEntityCofigureTemplate"
      :containerWidth="getIsMobile ? '100%' : '40%'"
    >
      <div class="mt-1 mb-1">
        <el-row>
          <el-link
            type="primary"
            icon="el-icon-plus"
            class="float-right"
            :underline="false"
            @click="addRefreshEntity(step)"
            >Add entity</el-link
          >
        </el-row>
        <el-scrollbar wrap-style="max-height: 60vh;">
          <el-row
            v-for="(entity, i) in entityRefreshList"
            :key="i + '_refresh_entity'"
          >
            <el-select
              v-model="entity.entity_from"
              placeholder="Take entity from"
              @change="(changedValue) => getEntityDetails(changedValue, entity)"
              clearable
              filterable
            >
              <el-option-group label="Relational"></el-option-group>
              <el-option-group label="Entity fields">
                <el-option
                  v-for="(field, i) in getFieldsByType(
                    ['ENTITY'],
                    allFieldsWithDataTable
                  )"
                  :key="field + i"
                  :value="field.key"
                  :label="field.label"
                ></el-option>
              </el-option-group>
            </el-select>
            <el-select
              v-if="entity.entity_id"
              v-model="entity.update_field"
              placeholder="Refresh entity"
              clearable
              filterable
            >
              <el-option
                v-for="(field, index) in (
                  actionEntityFields[entity.entity_id] || []
                ).filter((e) => checkAutofillEntityData(e))"
                :key="field + index"
                :value="field.key"
                :label="field.label"
                :disabled="
                  entityRefreshList.find(
                    (en, j) => field.key == en.update_field && j !== i
                  )
                    ? true
                    : false
                "
              ></el-option>
            </el-select>
            <el-link
              type="danger"
              :underline="false"
              icon="el-icon-delete"
              @click="entityRefreshList.splice(i, 1)"
            ></el-link>
          </el-row>
        </el-scrollbar>
      </div>
    </dialog-component>
    <!-- Settings modal -->
    <dialog-component
      :visible="settingsModal"
      :title="'Settings'"
      @before-close="resetEditModal"
      :containerWidth="'50%'"
    >
      <el-scrollbar wrap-style="max-height: 60vh;" style="padding: 10px">
        <div
          class="popUp-content"
          v-if="editForm && editForm.permission_settings"
        >
          <el-checkbox v-model="editForm.permission_settings.lock_folder"
            >Lock folder?</el-checkbox
          >
          <div
            class="d-flex mt-1 mb-1"
            v-if="editForm.permission_settings.lock_folder"
          >
            <span>Lock folder for</span>
            <el-select
              v-model="editForm.permission_settings.application_users"
              clearable
              placeholder="Select user"
              multiple
              size="mini"
              style="width: 120px"
              collapse-tags
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL'
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
            <span class="mt-1">after </span>
            <el-input-number
              :min="0"
              type="number"
              size="mini"
              v-model="editForm.permission_settings.value"
            ></el-input-number>
            <el-select
              v-model="editForm.permission_settings.type"
              placeholder="Type"
              size="mini"
              style="width: 80px"
            >
              <el-option value="DAY" label="Day"></el-option>
              <el-option value="MONTH" label="Months"></el-option>
              <el-option value="YEAR" label="Years"></el-option>
            </el-select>
            from created date
            <el-tooltip
              class="item"
              effect="dark"
              :content="
                'After that selected user not able to do upload files into current folder. Taking 0 will be current' +
                editForm.permission_settings.type
              "
              placement="bottom"
            >
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </div>
        <div
          class="popUp-content"
          v-if="editForm && editForm.approval_settings"
        >
          <el-checkbox v-model="editForm.approval_settings.approval_folder"
            >Approval folder?</el-checkbox
          >
          <div
            class="mt-1 mb-1"
            style="display: flex; flex-direction: column"
            v-if="editForm.approval_settings.approval_folder"
          >
            <h5 class="mt-1">Notify approver settings</h5>
            <span>Requesting users</span>
            <el-select
              v-model="editForm.approval_settings.requesting_users"
              clearable
              placeholder="Requesting user"
              multiple
              size="mini"
              collapse-tags
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL'
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>

            <span>Approver user type</span>
            <el-select
              v-model="editForm.approval_settings.approver_type"
              placeholder="Approver type"
              size="mini"
            >
              <el-option
                value="APPLICATION_USER"
                label="Application user"
              ></el-option>
              <el-option value="COMPANY_USER" label="Company user"></el-option>
              <el-option
                value="BASED_ON_DATA"
                label="Based on data"
              ></el-option>
            </el-select>
            <span
              v-if="
                editForm.approval_settings.approver_type !== 'BASED_ON_DATA'
              "
              >Approver type</span
            >
            <el-select
              v-model="editForm.approval_settings.approver_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_settings.approver_type == 'APPLICATION_USER'
              "
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL'
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="editForm.approval_settings.approver_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="editForm.approval_settings.approver_type == 'COMPANY_USER'"
            >
              <el-option
                v-for="item in getUserTypeList"
                :key="item._id"
                :label="getLabel(item)"
                :value="item._id"
              ></el-option>
            </el-select>
            <span
              v-if="
                editForm.approval_settings.approver_type !== 'BASED_ON_DATA'
              "
              >Approver users</span
            >
            <el-select
              v-model="editForm.approval_settings.approver_users"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_settings.approver_type !== 'BASED_ON_DATA'
              "
            >
              <el-option
                v-for="(user, i) in getUserEmailList(
                  editForm.approval_settings.approver_user_ids,
                  editForm.approval_settings.approver_type
                )"
                :key="user + 'user' + i"
                :value="user.email"
                :label="user.email"
              ></el-option>
            </el-select>
            <span
              v-if="editForm.approval_settings.approver_type == 'BASED_ON_DATA'"
              >Approver user fields</span
            >
            <el-select
              v-model="editForm.approval_settings.approver_fields"
              clearable
              placeholder="Approver user fields"
              multiple
              size="mini"
              collapse-tags
              v-if="editForm.approval_settings.approver_type == 'BASED_ON_DATA'"
            >
              <el-option
                v-for="(field, i) in getUserEntities(
                  getFieldsByType(['ENTITY'], allFieldsWithDataTable)
                )"
                :key="field + i"
                :value="field.key"
                :label="field.label"
              ></el-option>
            </el-select>
            <!--Approval mail template settings-->
            <h5 class="mt-1">Approver Email template settings</h5>
            <div style="display: flex">
              <div style="width: 49%; margin-right: 2%">
                <span>Subject</span>
                <el-input
                  v-model="
                    editForm.approval_settings.mail_template_data.subject
                  "
                  placeholder="Enter subject"
                ></el-input>
              </div>
              <div style="width: 49%">
                <span>Select Field to copy</span>
                <el-select
                  style="width: 100%"
                  v-model="copyField"
                  placeholder="Select Field to copy"
                  filterable
                  class="email-field"
                  @change="copyURL"
                >
                  <el-option
                    v-for="(item, index) of getSupportedSubjectFields()"
                    v-bind:key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                    {{ item.label }} ({{ item.input_type }})
                    <a
                      v-if="item.isSubjectSupported"
                      @click.stop="
                        pushToSubject(item.label, 'approval_settings')
                      "
                    >
                      <i class="el-icon-right" style="color: #409eff"></i>
                    </a>
                  </el-option>
                </el-select>
              </div>
            </div>
            <span class="mt-1">Custom message</span>
            <span class="warning font-class"
              >Note: Don't add User fields because there may be multiple users,
              we only take first user details.</span
            >
            <ckeditor
              v-model="editForm.approval_settings.mail_template_data.body"
              :config="editorConfig1"
            ></ckeditor>
            <div
              @click="chooseFromParentEmailTemplates('APPROVER')"
              style="cursor: pointer; color: #409eff; margin-left: 200px"
            >
              <span v-if="!editForm.approval_settings.mail_template_data.name"
                >Choose Parent Template(Header & Footer)</span
              >
              <span v-else>Change Parent Template (Header & Footer)</span>
            </div>
            <div
              v-if="editForm.approval_settings.mail_template_data.name"
              class="mb-2"
              style="margin-left: 200px"
            >
              Selected Parent :
              <span>{{
                editForm.approval_settings.mail_template_data.name
              }}</span>
              <span>
                <i
                  style="cursor: pointer"
                  @click="resetParent"
                  class="el-icon-error"
                ></i>
              </span>
            </div>
            <!--Approval mail template settings-->

            <!--User mail template settings after approvals-->
            <h5 class="mt-1">Notify user after approval settings</h5>
            <el-checkbox v-model="editForm.approval_notify_settings.notify_user"
              >Notify after approval?</el-checkbox
            >
            <span v-if="editForm.approval_notify_settings.notify_user"
              >User type</span
            >
            <el-select
              v-if="editForm.approval_notify_settings.notify_user"
              v-model="editForm.approval_notify_settings.notify_user_type"
              placeholder="Approver type"
              size="mini"
            >
              <el-option
                value="APPLICATION_USER"
                label="Application user"
              ></el-option>
              <el-option value="COMPANY_USER" label="Company user"></el-option>
              <el-option
                value="BASED_ON_DATA"
                label="Based on data"
              ></el-option>
            </el-select>
            <span
              v-if="
                editForm.approval_notify_settings.notify_user &&
                editForm.approval_notify_settings.notify_user_type !==
                  'BASED_ON_DATA'
              "
              >Type</span
            >
            <el-select
              v-model="editForm.approval_notify_settings.notify_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_notify_settings.notify_user &&
                editForm.approval_notify_settings.notify_user_type ==
                  'APPLICATION_USER'
              "
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL'
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="editForm.approval_notify_settings.notify_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_notify_settings.notify_user &&
                editForm.approval_notify_settings.notify_user_type ==
                  'COMPANY_USER'
              "
            >
              <el-option
                v-for="item in getUserTypeList"
                :key="item._id"
                :label="getLabel(item)"
                :value="item._id"
              ></el-option>
            </el-select>
            <span v-if="editForm.approval_notify_settings.notify_user"
              >Users</span
            >
            <el-select
              v-model="editForm.approval_notify_settings.notify_users"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_notify_settings.notify_user &&
                editForm.approval_notify_settings.notify_user_type !==
                  'BASED_ON_DATA'
              "
            >
              <el-option
                v-for="(user, i) in getUserEmailList(
                  editForm.approval_notify_settings.notify_user_ids,
                  editForm.approval_notify_settings.notify_user_type
                )"
                :key="user + 'user' + i"
                :value="user.email"
                :label="user.email"
              ></el-option>
            </el-select>
            <el-select
              v-model="editForm.approval_notify_settings.notify_user_fields"
              clearable
              placeholder="Approver user fields"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.approval_notify_settings.notify_user &&
                editForm.approval_notify_settings.notify_user_type ==
                  'BASED_ON_DATA'
              "
            >
              <el-option
                v-for="(field, i) in getUserEntities(
                  getFieldsByType(['ENTITY'], allFieldsWithDataTable)
                )"
                :key="field + i"
                :value="field.key"
                :label="field.label"
              ></el-option>
            </el-select>
            <h5
              class="mt-1"
              v-if="editForm.approval_notify_settings.notify_user"
            >
              Email template settings
            </h5>
            <!-- <span v-if="editForm.approval_notify_settings.notify_user"
              >Subject</span
            > -->
            <div
              v-if="editForm.approval_notify_settings.notify_user"
              style="display: flex"
            >
              <div style="width: 49%; margin-right: 2%">
                <span>Subject</span>
                <el-input
                  v-model="
                    editForm.approval_notify_settings.mail_template_data.subject
                  "
                  placeholder="Enter subject"
                ></el-input>
              </div>
              <div style="width: 49%">
                <span>Select Field to copy</span>
                <el-select
                  style="width: 100%"
                  v-model="copyField"
                  placeholder="Select Field to copy"
                  filterable
                  class="email-field"
                  clearable
                  @change="copyURL"
                >
                  <el-option
                    v-for="(item, index) of getSupportedSubjectFields()"
                    v-bind:key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                    {{ item.label }} ({{ item.input_type }})
                    <a
                      v-if="item.isSubjectSupported"
                      @click.stop="
                        pushToSubject(item.label, 'approval_notify_settings')
                      "
                    >
                      <i class="el-icon-right" style="color: #409eff"></i>
                    </a>
                  </el-option>
                </el-select>
              </div>
            </div>
            <span
              class="mt-1"
              v-if="editForm.approval_notify_settings.notify_user"
              >Custom message</span
            >
            <span
              class="warning font-class"
              v-if="editForm.approval_notify_settings.notify_user"
              >Note: Don't add Approve fields because there may be multiple
              approvers, we only take first approver details.</span
            >
            <ckeditor
              v-if="editForm.approval_notify_settings.notify_user"
              v-model="
                editForm.approval_notify_settings.mail_template_data.body
              "
              :config="editorConfig2"
            ></ckeditor>
            <div
              v-if="editForm.approval_notify_settings.notify_user"
              @click="chooseFromParentEmailTemplates('APPROVE_USER')"
              style="cursor: pointer; color: #409eff; margin-left: 200px"
            >
              <span
                v-if="
                  !editForm.approval_notify_settings.mail_template_data.name
                "
                >Choose Parent Template(Header & Footer)</span
              >
              <span v-else>Change Parent Template (Header & Footer)</span>
            </div>
            <div
              v-if="
                editForm.approval_notify_settings.mail_template_data.name &&
                editForm.approval_notify_settings.notify_user
              "
              class="mb-2"
              style="margin-left: 200px"
            >
              Selected Parent :
              <span>{{
                editForm.approval_notify_settings.mail_template_data.name
              }}</span>
              <span>
                <i
                  style="cursor: pointer"
                  @click="resetParent"
                  class="el-icon-error"
                ></i>
              </span>
            </div>
            <!--User mail template settings after approvals-->

            <!--User mail template settings after Decline-->
            <h5 class="mt-1">Notify user after decline settings</h5>
            <el-checkbox v-model="editForm.decline_notify_settings.notify_user"
              >Notify after decline?</el-checkbox
            >
            <span v-if="editForm.decline_notify_settings.notify_user"
              >User type</span
            >
            <el-select
              v-if="editForm.decline_notify_settings.notify_user"
              v-model="editForm.decline_notify_settings.notify_user_type"
              placeholder="Approver type"
              size="mini"
            >
              <el-option
                value="APPLICATION_USER"
                label="Application user"
              ></el-option>
              <el-option value="COMPANY_USER" label="Company user"></el-option>
              <el-option
                value="BASED_ON_DATA"
                label="Based on data"
              ></el-option>
            </el-select>
            <span
              v-if="
                editForm.decline_notify_settings.notify_user &&
                editForm.decline_notify_settings.notify_user_type !==
                  'BASED_ON_DATA'
              "
              >Type</span
            >
            <el-select
              v-model="editForm.decline_notify_settings.notify_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.decline_notify_settings.notify_user &&
                editForm.decline_notify_settings.notify_user_type ==
                  'APPLICATION_USER'
              "
            >
              <el-option
                v-for="(entity, i) in (allEntities || []).filter(
                  (e) =>
                    e.entity_type == 'BUSINESS' || e.entity_type == 'INDIVIDUAL'
                )"
                :key="entity + 'entity' + i"
                :value="entity._id"
                :label="entity.name"
              ></el-option>
            </el-select>
            <el-select
              v-model="editForm.decline_notify_settings.notify_user_ids"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.decline_notify_settings.notify_user &&
                editForm.decline_notify_settings.notify_user_type ==
                  'COMPANY_USER'
              "
            >
              <el-option
                v-for="item in getUserTypeList"
                :key="item._id"
                :label="getLabel(item)"
                :value="item._id"
              ></el-option>
            </el-select>
            <span v-if="editForm.decline_notify_settings.notify_user"
              >Users</span
            >
            <el-select
              v-model="editForm.decline_notify_settings.notify_users"
              clearable
              placeholder="Approver users"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.decline_notify_settings.notify_user &&
                editForm.decline_notify_settings.notify_user_type !==
                  'BASED_ON_DATA'
              "
            >
              <el-option
                v-for="(user, i) in getUserEmailList(
                  editForm.decline_notify_settings.notify_user_ids,
                  editForm.decline_notify_settings.notify_user_type
                )"
                :key="user + 'user' + i"
                :value="user.email"
                :label="user.email"
              ></el-option>
            </el-select>
            <el-select
              v-model="editForm.decline_notify_settings.notify_user_fields"
              clearable
              placeholder="Approver user fields"
              multiple
              size="mini"
              collapse-tags
              v-if="
                editForm.decline_notify_settings.notify_user &&
                editForm.decline_notify_settings.notify_user_type ==
                  'BASED_ON_DATA'
              "
            >
              <el-option
                v-for="(field, i) in getUserEntities(
                  getFieldsByType(['ENTITY'], allFieldsWithDataTable)
                )"
                :key="field + i"
                :value="field.key"
                :label="field.label"
              ></el-option>
            </el-select>
            <h5
              class="mt-1"
              v-if="editForm.decline_notify_settings.notify_user"
            >
              Email template settings
            </h5>
            <div
              v-if="editForm.decline_notify_settings.notify_user"
              style="display: flex"
            >
              <div style="width: 49%; margin-right: 2%">
                <span>Subject</span>
                <el-input
                  v-model="
                    editForm.decline_notify_settings.mail_template_data.subject
                  "
                  placeholder="Enter subject"
                ></el-input>
              </div>
              <div style="width: 49%">
                <span>Select Field to copy</span>
                <el-select
                  style="width: 100%"
                  v-model="copyField"
                  placeholder="Select Field to copy"
                  filterable
                  class="email-field"
                  clearable
                  @change="copyURL"
                >
                  <el-option
                    v-for="(item, index) of getSupportedSubjectFields()"
                    v-bind:key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                    {{ item.label }} ({{ item.input_type }})
                    <a
                      v-if="item.isSubjectSupported"
                      @click.stop="
                        pushToSubject(item.label, 'decline_notify_settings')
                      "
                    >
                      <i class="el-icon-right" style="color: #409eff"></i>
                    </a>
                  </el-option>
                </el-select>
              </div>
            </div>
            <span
              class="mt-1"
              v-if="editForm.decline_notify_settings.notify_user"
              >Custom message</span
            >
            <span
              class="warning font-class"
              v-if="editForm.decline_notify_settings.notify_user"
              >Note: Don't add Approve fields because there may be multiple
              approvers, we only take first approver details.</span
            >
            <ckeditor
              v-if="editForm.decline_notify_settings.notify_user"
              v-model="editForm.decline_notify_settings.mail_template_data.body"
              :config="editorConfig3"
            ></ckeditor>
            <div
              v-if="editForm.decline_notify_settings.notify_user"
              @click="chooseFromParentEmailTemplates('DECLINE_USER')"
              style="cursor: pointer; color: #409eff; margin-left: 200px"
            >
              <span
                v-if="!editForm.decline_notify_settings.mail_template_data.name"
                >Choose Parent Template(Header & Footer)</span
              >
              <span v-else>Change Parent Template (Header & Footer)</span>
            </div>
            <div
              v-if="
                editForm.decline_notify_settings.mail_template_data.name &&
                editForm.decline_notify_settings.notify_user
              "
              class="mb-2"
              style="margin-left: 200px"
            >
              Selected Parent :
              <span>{{
                editForm.decline_notify_settings.mail_template_data.name
              }}</span>
              <span>
                <i
                  style="cursor: pointer"
                  @click="resetParent"
                  class="el-icon-error"
                ></i>
              </span>
            </div>
            <!--User mail template settings after decline-->
          </div>
        </div>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetEditModal">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkLockFolderDisabled(editForm)"
          @click="update"
          :loading="loading"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :visible="openNewFileModal"
      :title="'Add new file or folder'"
      @before-close="resetModal"
      :containerWidth="'50%'"
    >
      <div class="popUp-content">
        <div>
          Type
          <el-select
            placeholder="Select type"
            v-model="newFile.type"
            style="width: 100%"
          >
            <el-option value="FOLDER" label="Folder"></el-option>
            <el-option
              value="UPLOAD_FOLDER"
              label="Upload Folder"
              v-if="actionEntityFoldersListType !== 'CURRENT_DATA'"
            ></el-option>
          </el-select>
        </div>
        <div class="mt-1">
          Name
          <el-input
            v-model="newFile.name"
            placeholder="Enter name"
            :disabled="newFile.name_mapped_with ? true : false"
            clearable
          ></el-input>
          OR
          <el-select
            placeholder="Create with"
            v-model="newFile.name_mapped_with"
            style="width: 100%"
            clearable
            :disabled="newFile.name ? true : false"
          >
            <el-option value="CURRENT_DATE" label="Current date"></el-option>
            <el-option value="CURRENT_DAY" label="Current day"></el-option>
            <el-option value="CURRENT_MONTH" label="Current month"></el-option>
            <el-option value="CURRENT_YEAR" label="Current year"></el-option>
          </el-select>
        </div>
        <div v-if="newFile.type == 'FILE'" class="mt-1">
          Upload file
          <el-upload
            class="avatar-uploader"
            action
            :show-file-list="false"
            :auto-upload="false"
            :on-change="upload"
            multiple
          >
            <div class="upload-attchment-box">
              <img
                src="@/assets/img/fields/attachment-filling.svg"
                alt="Upload File"
                height="20"
              />
              <h4 class="ml-1">Upload a file</h4>
            </div>
          </el-upload>
          <div
            class="mt-1"
            v-if="newFile && newFile.files && newFile.files.length"
          >
            <div v-for="(file, i) in newFile.files" :key="i" class="d-flex">
              <i class="el-icon-document" style="margin-top: 5px"></i>
              <span>{{ file.name }}</span>
              <el-link
                icon="el-icon-close"
                type="primary"
                @click="removeFile(i)"
                :underline="false"
                style="margin-left: 5px"
              ></el-link>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetModal">Close</el-button>
        <el-button type="primary" :disabled="checkDisabled" @click="saveFolder"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :title="'Notify'"
      :visible="configureEmailTemplate"
      :isShowFooter="false"
      :containerWidth="'52%'"
      @before-close="configureEmailTemplateDialog"
    >
      <EmailConfig
        v-loading="emailConfigLoading"
        :element-loading-text="loadingText"
        :currentEntity="currentEntity"
        :isSendIndividual="true"
        :isAutomation="true"
        :keyValue="this.emailConfigKey"
        :paramsContent="paramsData"
        :allFields="
          getUserEntities(getFieldsByType(['ENTITY'], allFieldsWithDataTable))
        "
        v-on:closeNotificationDialog="closeSendNotificationDialog"
        class="popUp-content"
      >
      </EmailConfig>
    </dialog-component>
    <dialog-component
      :title="
        'Map ' + currentEntity.name + ' field values to the formbuilder fields'
      "
      :visible="mapFieldsToFormbuilderModel"
    >
      <div>
        <el-scrollbar wrap-style="max-height: 60vh;">
          <div style="text-align: left; background-color: #ecf5ff">
            <el-collapse accordion>
              <el-collapse-item
                name="1"
                v-for="(step, i) in actionFormbuilderSteps"
                :key="i + '_step'"
                class="rule-collapse"
              >
                <template slot="title">
                  <div
                    style="width: 100%; background-color: #ecf5ff"
                    class="ml-1"
                  >
                    <label
                      v-if="
                        step.type == 'TEMPLATE' &&
                        allTemplateFieldMap[step.template]
                      "
                    >
                      <b>
                        {{ i + 1 }}.
                        {{ allTemplateFieldMap[step.template].name }}
                      </b>
                    </label>
                    <label v-if="step.type == 'ENTITY' && step.name">
                      <b>
                        {{ i + 1 }}.
                        {{ step.name }}
                      </b>
                    </label>
                  </div>
                </template>
                <!-- || e.type!= "CONTENT" -->
                <el-table
                  :data="
                    step.type == 'TEMPLATE' &&
                    allTemplateFieldMap[step.template]
                      ? allTemplateFieldMap[step.template].fields.filter(
                          (e) =>
                            ['FORMULA', 'SIGNATURE'].indexOf(e.input_type) ===
                            -1
                        )
                      : step.type == 'ENTITY'
                      ? getAllEntityFields(step.entity)
                      : []
                  "
                  size="mini"
                  border
                >
                  <el-table-column label="Field" prop="label" width="200">
                    <template slot-scope="{ row }">
                      <div
                        style="
                          font-weight: 500;
                          font-size: 12px;
                          color: #030503;
                        "
                      >
                        {{ row.label }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Mapped with" prop="key" width="250">
                    <template slot-scope="scope">
                      <div class="d-flex">
                        <el-select
                          v-model="
                            actionEntityPrimaryFieldsMap[
                              'mapped/' + step._id + '#' + scope.row.key
                            ]
                          "
                          placeholder="Update with"
                          clearable
                          filterable
                          :disabled="
                            actionEntityPrimaryFieldsMap[
                              step._id + '#' + scope.row.key
                            ] &&
                            actionEntityPrimaryFieldsMap[
                              step._id + '#' + scope.row.key
                            ].length
                              ? true
                              : false
                          "
                        >
                          <el-option
                            v-for="(field, i) in fetchTemplateFieldsByType(
                              [scope.row.inputType],
                              allCurrentEntityFields,
                              scope.row.entity_id
                            )"
                            :key="field + i"
                            :value="field.key"
                            :label="field.label"
                            :disabled="tableFieldDisabled(field)"
                          ></el-option>
                          <el-option
                            v-if="
                              scope.row.input_type == 'ENTITY' &&
                              scope.row.entity_id == currentEntity._id
                            "
                            :label="
                              'Current ' +
                              (currentEntity && currentEntity.name
                                ? currentEntity.name
                                : 'entity')
                            "
                            value="currentEntityData"
                          ></el-option>
                        </el-select>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Default value" prop="key" width="250">
                    <template slot-scope="scope">
                      <AnyFieldsExecute
                        v-if="
                          actionEntityPrimaryFieldsMap[
                            'mapped/' + step._id + '#' + scope.row.key
                          ]
                            ? false
                            : getFieldProperty(
                                step.type == 'TEMPLATE' &&
                                  allTemplateFieldMap[step.template]
                                  ? allTemplateFieldMap[
                                      step.template
                                    ].fields.filter(
                                      (e) =>
                                        [
                                          'FORMULA',
                                          'SIGNATURE',
                                          'DATA_TABLE',
                                        ].indexOf(e.input_type) === -1
                                    )
                                  : step.type == 'ENTITY'
                                  ? getAllEntityFields(step.entity)
                                  : [],
                                scope.row.key,
                                'key',
                                '',
                                {}
                              ).input_type == 'DATA_TABLE'
                            ? false
                            : true
                        "
                        :fieldsData="
                          step.type == 'TEMPLATE' &&
                          allTemplateFieldMap[step.template]
                            ? allTemplateFieldMap[step.template].fields.filter(
                                (e) =>
                                  [
                                    'FORMULA',
                                    'SIGNATURE',
                                    'DATA_TABLE',
                                  ].indexOf(e.input_type) === -1
                              )
                            : step.type == 'ENTITY'
                            ? getAllEntityFields(step.entity)
                            : []
                        "
                        :field="{
                          ...getFieldProperty(
                            step.type == 'TEMPLATE' &&
                              allTemplateFieldMap[step.template]
                              ? allTemplateFieldMap[
                                  step.template
                                ].fields.filter(
                                  (e) =>
                                    [
                                      'FORMULA',
                                      'SIGNATURE',
                                      'DATA_TABLE',
                                    ].indexOf(e.input_type) === -1
                                )
                              : step.type == 'ENTITY'
                              ? getAllEntityFields(step.entity)
                              : [],
                            scope.row.key,
                            'key',
                            '',
                            {}
                          ),
                          ...{
                            key: step._id + '#' + scope.row.key,
                          },
                        }"
                        :form="actionEntityPrimaryFieldsMap"
                      ></AnyFieldsExecute>
                    </template>
                  </el-table-column>
                </el-table>
              </el-collapse-item>
            </el-collapse>
          </div>
        </el-scrollbar>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPrimarymapTemplate">Close</el-button>
        <el-button
          type="primary"
          :disabled="checkSaveDisable"
          @click="savePrimarymapTemplate"
          >Save</el-button
        >
      </span>
    </dialog-component>
    <AutomationConfigureBlock
      :isOpen="configAutomateModel"
      :allFields="allFields"
      :step="step"
      :automationRules="automationRules"
      :automationRulesType="rulesType"
      @saveAutomationRules="saveAutomation"
      @deleteAutomationRule="deleteAutomationRule"
    ></AutomationConfigureBlock>
    <el-dialog
      title="Parent Templates(Header Footer)"
      :visible.sync="emailTemplatesParentListDialog"
      :show-close="true"
      width="100%"
    >
      <div
        v-if="
          editForm &&
          emailTemplatesParentListType == 'APPROVE_USER' &&
          editForm.approval_notify_settings &&
          editForm.approval_notify_settings.mail_template_data
        "
      >
        <singleEntityTemplatesList
          :content="editForm.approval_notify_settings.mail_template_data"
          :entity_id="currentEntity._id"
          type="PARENT"
          v-on:closeDialog="closeEmailFooter"
        />
      </div>
      <div
        v-else-if="
          editForm &&
          emailTemplatesParentListType == 'DECLINE_USER' &&
          editForm.decline_notify_settings &&
          editForm.decline_notify_settings.mail_template_data
        "
      >
        <singleEntityTemplatesList
          :content="editForm.decline_notify_settings.mail_template_data"
          :entity_id="currentEntity._id"
          type="PARENT"
          v-on:closeDialog="closeEmailFooter"
        />
      </div>
      <div
        v-else-if="
          editForm &&
          editForm.approval_settings &&
          editForm.approval_settings.mail_template_data
        "
      >
        <singleEntityTemplatesList
          :content="editForm.approval_settings.mail_template_data"
          :entity_id="currentEntity._id"
          type="PARENT"
          v-on:closeDialog="closeEmailFooter"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutomationHelper from "@/mixins/AutomationHelper";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import { ckEditorConfig } from "@/assets/data/ckeditor_config.ts";
export default {
  mixins: [AutomationHelper, EntitiesHelper],
  components: {
    ConditionalBlock: () => import("./EntityConditionalBlocks.vue"),
    AnyFieldsExecute: () =>
      import("../templates/formComponentsExecute/AnyFieldsExecute.vue"),
    AutomationConfigureBlock: () => import("./EntityAutomationBlock.vue"),
    EmailConfig: () => import("../notifications/emailConfig.vue"),
    singleEntityTemplatesList: () =>
      import("@/components/emailTemplates/singleEntityTemplatesList.vue"),
  },
  name: "Entity-Conditional-Blocks",
  props: [
    "step",
    "listType",
    "entityAllFilters",
    "showTopAdd",
    "currentEntity",
    "allEntities",
    "form",
    "allTemplates",
    "allTemplateFieldMap",
    "existedDataConditionMap",
    "allEntityFilters",
    "relationshipsData",
    "mainAction",
    "allFormbuilders",
    "entityRootFolders",
    "company_users",
    "allContacts",
  ],
  data() {
    return {
      otherEntityOption: [
        { key: "ADD", label: "Add" },
        { key: "UPDATE", label: "Update" },
        { key: "REFRESH_ENTITY", label: "Refresh entity" },
        { key: "AUTOMATE", label: "Automate" },
      ],
      selfEntityOption: [
        { key: "UPDATE", label: "Update" },
        { key: "AUTOMATE", label: "Automate" },
        { key: "SEND EMAIL", label: "Notification" },
      ],
      loading: false,
      mapPrimaryFieldsModal: false,
      configureEmailTemplate: false,
      actionEntityPrimaryFieldsMap: {},
      actionEntityPrimaryFields: [],
      selectedNodeIndex: null,
      actionSelfUpdateValues: {},
      actionEntityTemplates: [],
      actionEntityFieldsOparatorMap: {},
      actionEntityFieldsOparationsMap: {},
      currentStep: null,
      addDataTableStepFilters: false,
      dataTablefilters: [],
      paramsData: {},
      emailConfigKey: "",
      emailConfigLoading: false,
      loadingText: "",
      selectedDatatableFilterStep: "",
      email_object: {},
      files: [],
      mapPaymentFieldsWithOtherEntityDialog: false,
      configAutomateModel: false,
      automationRules: [],
      automationRulesType: true,
      rulesType: true,
      selectedEntityRelationships: [],
      docFields: [],
      selectedDocuments: [],
      sendDataAsAttachment: false,
      copyField: "",
      mapFolderCofigureModal: false,
      actionEntityFoldersList: [],
      actionEntityFoldersListType: "ROOT",
      actionEntityFoldersListFolder: "",
      deleteActionEntityFoldersList: {
        type: "ROOT",
        query_on: "CREATED",
        query_type: "YEAR",
        query_duration: 1,
        folder: "",
      },
      openNewFileModal: false,
      newFile: {
        name: "",
        type: "FOLDER",
        path: "",
        file: null,
        file_format: "",
        files: [],
        entityRelationships: [],
        sharedList: [],
        name_mapped_with: "",
        permission_settings: {
          type: "DAY",
          value: 1,
          application_users: [],
          lock_folder: false,
        },
        approval_settings: {
          approval_folder: false,
          requesting_users: [],
          approver_type: "APPLICATION_USER",
          approver_user_ids: [],
          approver_users: [],
          mail_template_data: {
            subject: "Approval request",
            body: "<p>Hello,</p>\n\n<p>Please click on the below link to approve it.</p>\n\n<p>Thank you.</p>\n",
            cc_user: [],
            header_body: "",
            footer_body: "",
            name: "",
          },
        },
        approval_notify_settings: {
          notify_user_type: "APPLICATION_USER",
          notify_user_ids: [],
          notify_users: [],
          notify_user_fields: [],
          notify_user: false,
          mail_template_data: {
            subject: "Files has approved",
            body: "<p>Hello,</p>\n\n<p>Please click on the below link to view files.</p>\n\n<p>Thank you.</p>\n",
            cc_user: [],
            header_body: "",
            footer_body: "",
            name: "",
          },
        },
        decline_notify_settings: {
          notify_user_type: "APPLICATION_USER",
          notify_user_ids: [],
          notify_users: [],
          notify_user_fields: [],
          notify_user: false,
          mail_template_data: {
            subject: "Files has declined",
            body: "<p>Hello,</p>\n\n<p>We are regret to inform you that your File has been rejected.</p>\n\n<p>Thank you.</p>\n",
            cc_user: [],
            header_body: "",
            footer_body: "",
            name: "",
          },
        },
      },
      editForm: {},
      editFormIndex: -1,
      settingsModal: false,
      fillWithRelationVisible: false,
      isMappingFields: false,
      mapFieldsToFormbuilderModel: false,
      deleteFolder: false,
      activeStep: 1,
      editorConfig: JSON.parse(JSON.stringify(ckEditorConfig)),
      editorConfig1: {},
      editorConfig2: {},
      editorConfig3: {},
      allMentionableFields: [],
      emailTemplatesParentListDialog: false,
      emailTemplatesParentListType: "APPROVER",
      entityRefreshList: [],
      entityRefreshModal: false,
    };
  },
  methods: {
    resetParent() {
      switch (this.emailTemplatesParentListType) {
        case "APPROVER":
          if (this.editForm?.approval_settings?.mail_template_data) {
            this.editForm.approval_settings.mail_template_data.footer_body = "";
            this.editForm.approval_settings.mail_template_data.header_body = "";
            this.editForm.approval_settings.mail_template_data.name = "";
          }
          break;
        case "APPROVE_USER":
          if (this.editForm?.approval_notify_settings?.mail_template_data) {
            this.editForm.approval_notify_settings.mail_template_data.footer_body =
              "";
            this.editForm.approval_notify_settings.mail_template_data.header_body =
              "";
            this.editForm.approval_notify_settings.mail_template_data.name = "";
          }
          break;
        case "DECLINE_USER":
          if (this.editForm?.decline_notify_settings?.mail_template_data) {
            this.editForm.decline_notify_settings.mail_template_data.footer_body =
              "";
            this.editForm.decline_notify_settings.mail_template_data.header_body =
              "";
            this.editForm.decline_notify_settings.mail_template_data.name = "";
          }
          break;
      }
    },
    chooseFromParentEmailTemplates(type) {
      this.emailTemplatesParentListType = type;
      this.emailTemplatesParentListDialog = true;
    },
    closeEmailFooter() {
      this.emailTemplatesParentListDialog = false;
    },
    getAllEntityMentionsFields() {
      let result = [];
      result = [...result, ...this.allFields];
      [
        "first_name",
        "last_name",
        "email",
        "phone",
        "title",
        "address",
        "name",
      ].forEach((f) => {
        result.push({
          mention_key: "user/" + f,
          label: "User - " + this.capitalizeFirstLetter(f.replaceAll("_", " ")),
          template_key: "user/" + f,
          key: f,
          input_type: "SINGLE_LINE_TEXT",
        });
      });
      [
        "first_name",
        "last_name",
        "email",
        "phone",
        "title",
        "address",
        "name",
      ].forEach((f) => {
        result.push({
          mention_key: "approver/" + f,
          label:
            "Approver - " + this.capitalizeFirstLetter(f.replaceAll("_", " ")),
          template_key: "approver/" + f,
          key: f,
          input_type: "SINGLE_LINE_TEXT",
        });
      });
      ["name", "created_at"].forEach((f) => {
        result.push({
          mention_key: "folder/" + f,
          label:
            "Folder - " + this.capitalizeFirstLetter(f.replaceAll("_", " ")),
          template_key: "folder/" + f,
          key: f,
          input_type: "SINGLE_LINE_TEXT",
        });
      });
      return result;
    },
    fetchMentionedFields() {
      let notSupportedSubjectTypes = [
        "IMAGE",
        "SIGNATURE",
        "PAY_BUTTON",
        "FILE",
        "DATA_TABLE",
        "MULTI_LINE_TEXT",
      ];
      this.allMentionableFields = this.getAllEntityMentionsFields();
      this.allMentionableFields = this.allMentionableFields.map((item) => {
        return {
          label: item.label,
          template_key: item.template_key,
          id: item.mention_key,
          input_type: item.inputType,
          isSubjectSupported: !notSupportedSubjectTypes.includes(
            item.inputType
          ),
        };
      });
    },
    getSupportedSubjectFields() {
      let supportedFields = this.allMentionableFields.filter(
        (e) => e.isSubjectSupported
      );
      return supportedFields;
    },
    copyURL(field) {
      navigator.clipboard.writeText(`[[${field}]]`);
      this.$message({
        message: `${field} Field Copied!`,
        type: "success",
      });
    },
    pushToSubject(field, type) {
      switch (type) {
        case "approval_settings":
          this.editForm.approval_settings.mail_template_data.subject =
            this.editForm.approval_settings.mail_template_data.subject +
            ` [[${field}]]`;
          break;
        case "approval_notify_settings":
          this.editForm.approval_notify_settings.mail_template_data.subject =
            this.editForm.approval_notify_settings.mail_template_data.subject +
            ` [[${field}]]`;
          break;
        case "decline_notify_settings":
          this.editForm.decline_notify_settings.mail_template_data.subject =
            this.editForm.decline_notify_settings.mail_template_data.subject +
            ` [[${field}]]`;
          break;
      }
      this.$message({
        message: `${field} added to Subject`,
        type: "success",
      });
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    dataFeed(opts, callback) {
      var matchProperty = "label",
        data = this.allMentionableFields.filter(function (item) {
          return (
            item[matchProperty]
              .toLowerCase()
              .indexOf(opts.query.toLowerCase()) !== -1
          );
        });
      data = data.sort(function (a, b) {
        return a[matchProperty].localeCompare(b[matchProperty], undefined, {
          sensitivity: "accent",
        });
      });
      callback(data);
    },
    configCKeditor() {
      this.editorConfig.mentions = [
        {
          feed: this.dataFeed,
          itemTemplate:
            '<li data-id="{id}">' +
            '<strong class="label">{label}</strong>' +
            "</li>",
          outputTemplate:
            "<span>&nbsp;</span><span>[[{label}]]</span><span>&nbsp;</span>",
          minChars: 0,
          convertTo: function (item) {
            return {
              template_key: item.label,
            };
          },
        },
        {
          feed: "/tags?name={encodedQuery}",
          marker: "#",
        },
      ];
      this.editorConfig1 = {
        ...this.editorConfig,
        id: "approval_settings",
      };
      this.editorConfig2 = {
        ...this.editorConfig,
        id: "approval_notify_settings",
      };
      this.editorConfig3 = {
        ...this.editorConfig,
        id: "decline_notify_settings",
      };
    },
    mapMailTemplatevariables(body, revert) {
      const childRegex = /\[\[(.*?)\]\]/g;
      const childVariables = [];
      let match;
      while ((match = childRegex.exec(body)) !== null) {
        childVariables.push(match[1]);
      }
      // Replace labels with their corresponding template_keys (need to change for other entities labels)
      body = body.replace(/\[\[(.*?)\]\]/g, (match, label) => {
        const template = this.allMentionableFields.find(
          (obj) =>
            (!revert && obj.label === label) || (revert && obj.id == label)
        );
        let value = "";
        if (revert) {
          value = template?.label;
        } else {
          value = template?.template_key;
        }
        if (template?.input_type == "PAY_BUTTON") {
          return template ? `[[${value}#pay]]` : match;
        }
        return template ? `[[${value}]]` : match;
      });
      return body;
    },
    update() {
      if (this.editForm?.approval_notify_settings?.mail_template_data?.body) {
        this.editForm.approval_notify_settings.mail_template_data.body =
          this.mapMailTemplatevariables(
            this.editForm.approval_notify_settings.mail_template_data.body
          );
      }
      if (
        this.editForm?.approval_notify_settings?.mail_template_data?.subject
      ) {
        this.editForm.approval_notify_settings.mail_template_data.subject =
          this.mapMailTemplatevariables(
            this.editForm.approval_notify_settings.mail_template_data.subject
          );
      }
      if (this.editForm?.decline_notify_settings?.mail_template_data?.body) {
        this.editForm.decline_notify_settings.mail_template_data.body =
          this.mapMailTemplatevariables(
            this.editForm.decline_notify_settings.mail_template_data.body
          );
      }
      if (this.editForm?.decline_notify_settings?.mail_template_data?.subject) {
        this.editForm.decline_notify_settings.mail_template_data.subject =
          this.mapMailTemplatevariables(
            this.editForm.decline_notify_settings.mail_template_data.subject
          );
      }
      if (this.editForm?.approval_settings?.mail_template_data?.body) {
        this.editForm.approval_settings.mail_template_data.body =
          this.mapMailTemplatevariables(
            this.editForm.approval_settings.mail_template_data.body
          );
      }
      if (this.editForm?.approval_settings?.mail_template_data?.subject) {
        this.editForm.approval_settings.mail_template_data.subject =
          this.mapMailTemplatevariables(
            this.editForm.approval_settings.mail_template_data.subject
          );
      }
      this.actionEntityFoldersList[this.editFormIndex] = JSON.parse(
        JSON.stringify({ ...this.editForm })
      );
      this.resetEditModal();
    },
    resetEditModal() {
      this.editForm = {};
      this.editFormIndex = -1;
      this.settingsModal = false;
    },
    openSettingsModal(file, i) {
      this.editForm = JSON.parse(JSON.stringify({ ...file }));
      if (!this.editForm.permission_settings) {
        this.editForm = JSON.parse(
          JSON.stringify({
            ...this.editForm,
            ...{
              permission_settings: {
                type: "DAY",
                value: 1,
                application_users: [],
                lock_folder: false,
              },
            },
          })
        );
      }
      if (!this.editForm.approval_settings) {
        this.editForm = JSON.parse(
          JSON.stringify({
            ...this.editForm,
            ...{
              approval_settings: {
                approval_folder: false,
                requesting_users: [],
                approver_type: "APPLICATION_USER",
                approver_user_ids: [],
                approver_users: [],
                mail_template_data: {
                  subject: "Approval request",
                  body: "<p>Hello,</p>\n\n<p>Please click on the below link to approve it.</p>\n\n<p>Thank you.</p>\n",
                  cc_user: [],
                  header_body: "",
                  footer_body: "",
                  name: "",
                },
              },
            },
          })
        );
      } else {
        if (!this.editForm?.approval_settings?.mail_template_data) {
          this.editForm["approval_settings"] = JSON.parse(
            JSON.stringify({
              ...this.editForm["approval_settings"],
              mail_template_data: {
                subject: "Approval request",
                body: "<p>Hello,</p>\n\n<p>Please click on the below link to approve it.</p>\n\n<p>Thank you.</p>\n",
                cc_user: [],
                header_body: "",
                footer_body: "",
                name: "",
              },
            })
          );
        } else {
          if (this.editForm?.approval_settings?.mail_template_data?.body) {
            this.editForm.approval_settings.mail_template_data.body =
              this.mapMailTemplatevariables(
                this.editForm.approval_settings.mail_template_data.body,
                true
              );
          }
          if (this.editForm?.approval_settings?.mail_template_data?.subject) {
            this.editForm.approval_settings.mail_template_data.subject =
              this.mapMailTemplatevariables(
                this.editForm.approval_settings.mail_template_data.subject,
                true
              );
          }
        }
      }
      if (!this.editForm.approval_notify_settings) {
        this.editForm = JSON.parse(
          JSON.stringify({
            ...this.editForm,
            ...{
              approval_notify_settings: {
                notify_user_type: "APPLICATION_USER",
                notify_user_ids: [],
                notify_users: [],
                notify_user_fields: [],
                mail_template_data: {
                  subject: "Files has approved",
                  body: "<p>Hello,</p>\n\n<p>Please click on the below link to approve it.</p>\n\n<p>Thank you.</p>\n",
                  cc_user: [],
                  header_body: "",
                  footer_body: "",
                  name: "",
                },
              },
            },
          })
        );
      } else {
        if (this.editForm?.approval_notify_settings?.mail_template_data?.body) {
          this.editForm.approval_notify_settings.mail_template_data.body =
            this.mapMailTemplatevariables(
              this.editForm.approval_notify_settings.mail_template_data.body,
              true
            );
        }
        if (
          this.editForm?.approval_notify_settings?.mail_template_data?.subject
        ) {
          this.editForm.approval_notify_settings.mail_template_data.subject =
            this.mapMailTemplatevariables(
              this.editForm.approval_notify_settings.mail_template_data.subject,
              true
            );
        }
      }

      if (!this.editForm.decline_notify_settings) {
        this.editForm = JSON.parse(
          JSON.stringify({
            ...this.editForm,
            ...{
              decline_notify_settings: {
                notify_user_type: "APPLICATION_USER",
                notify_user_ids: [],
                notify_users: [],
                notify_user_fields: [],
                mail_template_data: {
                  subject: "Files has declined",
                  body: "<p>Hello,</p>\n\n<p>We are regret to inform you that your File has been rejected.</p>\n\n<p>Thank you.</p>\n",
                  cc_user: [],
                  header_body: "",
                  footer_body: "",
                  name: "",
                },
              },
            },
          })
        );
      } else {
        if (this.editForm?.decline_notify_settings?.mail_template_data?.body) {
          this.editForm.decline_notify_settings.mail_template_data.body =
            this.mapMailTemplatevariables(
              this.editForm.decline_notify_settings.mail_template_data.body,
              true
            );
        }
        if (
          this.editForm?.decline_notify_settings?.mail_template_data?.subject
        ) {
          this.editForm.decline_notify_settings.mail_template_data.subject =
            this.mapMailTemplatevariables(
              this.editForm.decline_notify_settings.mail_template_data.subject,
              true
            );
        }
      }

      this.editFormIndex = i;
      this.settingsModal = true;
    },
    notifyUser(value) {
      if (value == "self/DELETE") {
        this.$message.warning("Warning. Selected data will be deleted");
      }
    },
    configureEmailTemplateDialog() {
      this.configureEmailTemplate = false;
    },
    saveAutomation(type) {
      // this.loading=true;
      // if(this.automationRules && this.automationRules.length)
      // {
      this.automationRulesType = type;
      this.saveAutomationHelper();
      // resetAutomationRules
      // }
    },
    deleteAutomationRule(keyIndex) {
      let otherRules = this.automationRules.filter(
        (node) => node && node.keyIndex != keyIndex
      );
      this.automationRules = [...otherRules];
    },
    // openAutomateDrawer(step){
    //   // automationConfigure
    //   this.configAutomate=!this.configAutomate;
    // },

    isEndNode(step) {
      let currentBlock = (this.form.then || []).findIndex(
        (e) => e.keyIndex == step.keyIndex
      );
      let conditionalBlock = (this.form.then || []).findIndex(
        (e) => e.type == "CONDITION"
      );
      return currentBlock >= conditionalBlock;
    },
    IsDataTableSelected(step) {
      if (step?.primary_data) {
        let dataTableKey = Object.keys(step.primary_data).find(
          (e) =>
            e.includes("mapped/") &&
            step.primary_data[e] &&
            step.primary_data[e].includes(":")
        );
        return dataTableKey ? true : false;
      }
      return false;
    },
    addNewAction(op, step) {
      if (step) {
        step[op].push({
          action: "other/UPDATE",
          type: "ACTION",
          then: [],
          else: [],
          keyIndex: (Math.random() + 1).toString(36).substring(7),
          entities_from: [],
        });
      } else {
        this.step[op].push({
          action: "other/UPDATE",
          type: "ACTION",
          then: [],
          else: [],
          keyIndex: (Math.random() + 1).toString(36).substring(7),
          entities_from: [],
        });
      }
    },
    // getFieldValue(field) {
    //     return `${field.template_id}#${field.key}`;
    // },
    disableOtherRows(row) {
      let tableFieldsMapped = Object.keys(
        this.actionEntityPrimaryFieldsMap
      ).filter((f) => this.actionEntityPrimaryFieldsMap[f].includes(":"));
      if (tableFieldsMapped.length) {
        return true;
      } else {
        if (
          Object.keys(this.actionEntityPrimaryFieldsMap).includes(
            `relation/${row.key}`
          )
        ) {
          return false;
        } else if (
          Object.keys(this.actionEntityPrimaryFieldsMap).find((e) =>
            e.includes("relation/")
          ) !== undefined
        ) {
          return true;
        }
      }
      return false;
    },
    tableFieldDisabled(field) {
      if (
        field.data_table_field_index &&
        field.data_table_field_index > -1 &&
        Object.keys(this.actionEntityPrimaryFieldsMap).find((e) =>
          e.includes("relation/")
        ) !== undefined
      ) {
        return true;
      } else {
        return false;
      }
    },
    rowValueChange(row) {
      if (this.actionEntityPrimaryFieldsMap["relation/" + row.key] == "") {
        delete this.actionEntityPrimaryFieldsMap["relation/" + row.key];
      } else {
        Object.keys(this.actionEntityPrimaryFields).map((e) => {
          if (e.includes("relation/") && e !== "relation/" + row.key) {
            delete this.actionEntityPrimaryFieldsMap[e];
          }
        });
      }
    },
    async closeSendNotificationDialog(data) {
      this.emailConfigLoading = true;
      if (data.attachments && data.attachments?.length) {
        this.loadingText = "Uploading Files";
        let files = data.attachments.map((e) => {
          return {
            name: e.filename,
            mimetype: e.type,
            data: e.content,
            size: e.size,
          };
        });
        let params = {
          files: files,
          path: "email-attachments",
        };
        await this.$store.dispatch(
          "s3FileUpload/uploadMultipleBase64Files",
          params
        );
        if (this.getFileUploadData && this.getFileUploadData.length) {
          data.attachments = this.getFileUploadData;
        }
      }
      this.loadingText = "Saving Data";
      this.email_object = {
        ...data,
      };
      this.configureEmailTemplate = false;
      this.emailConfigLoading = false;
      this.saveEmailConfigurations();
    },
    getUserEmailList(approver_user_ids, approver_type) {
      if (
        this.editForm?.approval_settings?.approval_folder &&
        approver_user_ids
      ) {
        if (approver_type == "APPLICATION_USER") {
          return this.allContacts.filter((e) => {
            if (e?.contact_types) {
              return e.contact_types.find((el) =>
                approver_user_ids.includes(
                  el?.contact_type?._id || el?.contact_type
                )
              )
                ? true
                : false;
            }
          });
        } else if (approver_type == "COMPANY_USER") {
          return this.company_users.filter((e) => {
            if (e?.rolesList) {
              return e.rolesList.find((el) =>
                approver_user_ids.includes(el?.userType?._id)
              )
                ? true
                : false;
            } else {
              return e.roles.find((el) =>
                approver_user_ids.includes(el?.userType_id)
              )
                ? true
                : false;
            }
          });
        }
        return [];
      }
      return [];
    },
    getEditorConfig(index) {
      const config = JSON.parse(JSON.stringify(ckEditorConfig));
      config.id = `editor_${index}`;
      return config;
    },
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("auth", [
      "getUserTypeList",
      "getActiveWorkspace",
      "getUserTypeEmailsErrors",
      "getUserTypeEmails",
      "getAuthenticatedUser",
    ]),
    ...mapGetters("entities", [
      "getEntityVariableFieldsInputTypes",
      "getEntitiesDataErrors",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
      "getParentEntityRelatiponshipData",
    ]),
    ...mapGetters("s3FileUpload", [
      "getFileUploadData",
      "getCompanyLogoUpdateData",
      "getFileUploadStatus",
    ]),
    stepsExist() {
      return this.steps && this.steps.length ? true : false;
    },
    steps() {
      return this.step && this.step[this.listType]
        ? this.step[this.listType]
        : [];
    },
    checkSaveDisable() {
      if (this.actionEntityPrimaryFieldsMap) {
        let emptyField = this.actionEntityPrimaryFields.find((e) => {
          if (
            !this.actionEntityPrimaryFieldsMap[e.key] &&
            !this.actionEntityPrimaryFieldsMap["mapped/" + e.key]
          ) {
            return e;
          }
        });
        if (emptyField) {
          return true;
        }
        return false;
      }
      return true;
    },
    filteredEntities() {
      if (this.currentEntity?._id) {
        return this.allEntities.filter((e) => e._id != this.currentEntity._id);
      }
      return this.allEntities;
    },
    relationships() {
      return (this.relationshipsData || []).map((el) => {
        if (el.child_entity_id == this.currentEntity._id) {
          el.relationship_title = el.parent_relation_title;
          el.relationship_id = el.parent_entity_id;
        } else {
          el.relationship_title = el.child_relation_title;
          el.relationship_id = el.child_entity_id;
        }
        return el;
      });
    },
    getLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
    checkUpdateActionOrNot() {
      return this.mainAction && this.mainAction.includes("template/")
        ? true
        : false;
    },
    checkSelfTriggerAction() {
      return (
        this.form?.when == "ADDED" || this.form?.when.includes("template/")
      );
    },
  },
  async mounted() {
    this.loading = true;
    if (this.currentEntity?.templates) {
      this.allFields = this.getAllFieldsFromEntity(this.currentEntity, true);
      this.allFieldsWithDataTable = this.getAllFieldsFromEntity(
        this.currentEntity,
        true,
        true
      );
      this.allCurrentEntityFields = this.getAllFieldsFromEntity(
        this.currentEntity
      );
    }
    (this.allEntities || []).forEach((entity) => {
      this.actionEntityFields[entity._id] = this.getAllFieldsFromEntity(
        entity,
        false
      );
    });
    await this.fetchMentionedFields();
    this.configCKeditor();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.warining {
  font-size: 10px;
  color: "#E6A23C" !important;
}
.delete-files-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.files-container {
  display: flex;
  gap: 20px;
  padding: 10px;
  .single-file {
    text-align: center !important;
    padding: 10px;
    .file-header {
      i {
        font-size: 50px;
      }
      img {
        width: 55px;
      }
    }
    cursor: pointer;
    &:hover {
      background-color: rgba(231, 234, 243, 0.4);
      .file-title {
        .action-button {
          visibility: visible !important;
        }
      }
    }
    .file-title {
      text-align: center !important;
      .action-button {
        visibility: hidden !important;
      }
    }
  }
}
.arrow-container {
  .left-label {
    position: relative;
    top: -50% !important;
    // left: 50px !important;
  }
  .right-label {
    position: relative;
    top: -50% !important;
    // left: -25% !important;
  }
  height: 100px;
  width: 1fr;
  text-align: center !important;
  .right-arrow {
    height: 150px;
    width: 50%;
    margin-top: 10%;
    border-top: 1px solid #dcdfe6;
    border-right: 1px solid #dcdfe6;
  }
  .left-arrow {
    height: 150px;
    width: 50%;
    margin-top: 10%;
    margin-left: 50%;
    border-top: 1px solid #dcdfe6;
    border-left: 1px solid #dcdfe6;
  }
  .left-add {
    margin-top: 25%;
    // margin-left: -100px;
  }
  .right-add {
    margin-top: 25%;
    // margin-left: 85%;
  }
}
.arrow {
  position: relative;
  left: 50%;
  top: -10%;
  height: 15px;
  border-left: 1px solid #dcdfe6;
}
.step-btn {
  color: #409eff;
  font-size: 12px;
  position: relative;
  left: 50%;
  top: -10%;
}
.arrow-head {
  width: 0;
  height: 0;
  left: 49%;
  top: 0;
  bottom: 0;
  position: relative;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #dcdfe6;
}
.symbol {
  text-align: center !important;
  .rectangle {
    background: #409eff;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(45deg);
  }
  .rectangle p {
    font-size: 10px;
    color: white;
    transform: rotate(-45deg);
  }
  .rect-action p {
    font-size: 10px;
    color: white;
  }
  .rect-action {
    background: #03cea4;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.track-bar {
  text-align: center !important;
  .circle {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #409eff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .circle p {
    font-size: 10px;
    color: white;
  }
}
.step-box {
  text-align: left !important;
  padding: 10px;
  border: 1px solid #dcdfe6;
  width: 500px !important;
}
.center-container {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // text-align: center !important;
}
.step-title {
  color: #303133;
  margin-top: 10px;
}
.action-row {
  display: inline-block;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
  column-gap: 5px;
}
.el-select {
  margin: 1vh;
}
.end-block {
  display: block;
  margin: 0 auto;
  padding: 0.5em 1.5em;
  border-radius: 5px;
  border: 1px dashed #ff3a79;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ff3a79;
  width: 500px;
}
.name-types {
  .filename {
    height: 35px;
    margin-top: 5px;
    cursor: pointer;
    background: #ffffff;
    .el-upload-list__item-name {
      .img-active {
        visibility: hidden;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .img-fluid {
        position: relative;
        top: 5px;
        width: 18.43px !important;
      }
    }
    // .delete-doc {
    //   visibility: hidden;
    // }
    .direction-buttons {
      display: flex;
      visibility: hidden;
      position: relative;
      top: -35%;
      left: 97%;
      .directions {
        position: relative;
        right: 10%;
        top: -10px !important;
      }
    }
    &:hover {
      .img-active {
        visibility: visible;
        width: 6px !important;
        margin-right: 10px;
        margin-left: 10px;
        position: relative;
        top: 3px;
      }
      .direction-buttons {
        display: flex;
        visibility: visible;
        position: relative;
        top: -35%;
        left: 97%;
        .directions {
          position: relative;
          right: 10%;
          top: -10px !important;
        }
      }
      // .delete-doc {
      //   visibility: visible;
      //   position: relative;
      //   top: -60%;
      //   left: 97%;
      // }
    }
  }
}
.document {
  width: 180px;
  margin: 10px;
}
.attachments {
  display: flex;
  justify-content: center;
  margin: 5px;
}
.upload-demo {
  margin: 10px;
}
.attachments-footer {
  margin-bottom: 50px;
}
.email-config-button {
  cursor: pointer;
}
[class*=" el-icon-"],
[class^="el-icon-"] {
  display: inline;
}
</style>

<style lang="scss">
.tippy-box {
  background-color: rgba(245, 0, 0, 0) !important;
}
.container-scroll {
  // border: 1px solid red;
  height: 80vh;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-right: 5px;
}
.container-scroll::-webkit-scrollbar {
  width: 0.5em;
}
.container-scroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.el-form-item {
  margin-bottom: 0px;
}
.el-form-item__label {
  padding: 0 !important;
  font-weight: 600;
  font-size: 14px;
}
</style>
<style>
.custom-input {
  width: 50em;
}
.scrollbar-content {
  scrollbar-width: thin;
  height: 400px;
  overflow-y: auto;
}
.font-class {
  font-size: 12px;
}
</style>
